import React from 'react'

import Navbar from "../inc/Navbar";
import Journeypage from './Journeypage';
import ScrollToTop from "react-scroll-to-top";

function Journey() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
           <br/>
           <br/>
            <ScrollToTop smooth top="100" color="darkBlue"/>


            <div className='desertmian'>
                <div className="album py-2">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Our Journey</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <Journeypage />

            <br />
        </div>
    )
}

export default Journey